<template>
	<div class="page-show-user" v-if="loaded">
		<div class="main-content">
			<div class="header-filter">
				<div class="row-space-tbf search-row">
					<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
					<div class="content">
						<div class="search-input">
							<input type="text" :placeholder="$t('general.search_by')" v-model="search_filter"/>
						</div>
						<div class="actions-page mobile">
							<button v-if="$resize && $mq.above(600)" class="btn-tbf grey filter-btn" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
								<div class="counter" v-bind:class="{active: queryFiltersCount > 0}">{{ queryFiltersCount }}</div>
								<span class="text">{{ $t('general.filters') }}</span>
								<div class="icon-filter">
									<icon-arrow />
								</div>
							</button>
							<button v-else-if="$resize && $mq.below(599)" class="btn-tbf grey filter-btn-mobile" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
								<div class="icon">
									<icon-settings />
								</div>
							</button>
						</div>
					</div>
					<div class="space-right"></div>
				</div>

				<div class="row-space-tbf filters-extend" :class="[{mobile: $resize && $mq.between([781, 905]) ||  $resize && $mq.between([0, 682])}]" v-if="extendFilters">
					<div class="space-left"></div>
					<div class="content">
						<div class="filter-dropdown dropdown">
							<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedRoles.length">{{ $t('filters.by_roles') }}</span>
								<span class="text" v-else-if="selectedRoles.length == 1">{{ filterRoles.find(el => el.id == selectedRoles[0]).name }}</span>
								<span class="text" v-else>{{ selectedRoles.length }} {{ $t('filters.roles_selected') }}</span>

								<div class="icon-filter" v-if="!selectedRoles.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedRoles')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="search-filter-dropdown">
									<input type="text" v-model="searchRoles" :placeholder="$t('filters.search_roles')">
								</div>

								<template v-for="role in filteredRoles">
									<div class="dropdown-item" @click.stop="selectOptionFilter('selectedRoles', role.id)" v-bind:class="{active: selectedRoles.includes(role.id)}" :key="'filter-role-' + role.id">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ role.name }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
						<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
							<icon-trash v-if="$resize && $mq.above(905) || $resize && $mq.between([682, 780])"/>
							<div v-else-if="$resize && $mq.between([781, 905]) ||  $resize && $mq.between([0, 682])">{{$t('filters.clear_all')}}</div>
						</button>
					</div>
					<div class="space-right"></div>
				</div>
			</div>

			<div class="data-list list-procedures">
				<div class="row-space-tbf header-list" v-bind:class="{'border-t-0': extendFilters}">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter sortable column-name" @click="sortList('name')" v-bind:class="[sortBy === 'name' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t('procedures.name') }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div v-if="$resize && ($mq.above(1115) || $mq.between([500,1024]))" class="column-filter column-reading-time">
							<div class="text">{{ $t('procedures.reading_time') }}</div>
						</div>
						<div v-if="$resize && ($mq.above(1115) || $mq.between([600,1024]))" class="column-filter column-entries">
							<div class="text">{{ $t('procedures.visits') }}</div>
						</div>
						<div v-if="$resize && $mq.above(1420)" class="column-filter column-roles">
							<div class="text">{{ $t('procedures.roles') }}</div>
						</div>
						<div v-if="$resize && ($mq.above(1235) || $mq.between([400,1024]))" class="column-filter sortable column-last-update" @click="sortList('updated_at')" v-bind:class="[sortBy === 'updated_at' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t('general.last_update') }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div class="column-filter column-edit-mobile" v-if="$resize && $mq.below(500)"></div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-if="sortedProcedures.length">
					<div class="row-space-tbf row-list-item" :key="'procedura' + procedure.id" v-for="procedure in sortedProcedures">
						<div class="space-left"><icon-procedures v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
							<div class="column-name-title column-name">
								<div class="circle-status"  :class="{active: procedure.status == 'live'}"></div>
								<div class="name" @click="showProcedure(procedure.slug)">{{ procedure.name }}</div>
							</div>
							<div v-if="$resize && ($mq.above(1115) || $mq.between([500,1024]))" class="column-simple-text column-reading-time">
								<div class="text">{{ procedure.analytics.time }}</div>
							</div>
							<div v-if="$resize && ($mq.above(1115) || $mq.between([600,1024]))" class="column-simple-text column-entries">
								<div class="text">{{ procedure.analytics.entries }}</div>
							</div>
							<div v-if="$resize && $mq.above(1420)" class="column-simple-text column-roles">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
									<div class="text roles">
										<span class="first" v-if="procedure.roles.length == 1">{{ procedure.roles[0].name }}</span>
										<span v-else-if="procedure.roles.length > 1">{{`${procedure.roles[0].name} (+${(procedure.roles.length - 1)})`}}</span>
									</div>
									<template slot="popover">
										<div class="simple-text"><span v-for="(role, index) in procedure.roles">{{index < procedure.roles.length - 1 ? role.name + ', ' : role.name}}</span></div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && ($mq.above(1235) || $mq.between([400,1024]))" class="column-simple-text column-last-update">
								<div class="text">{{ procedure.updated_at | moment('DD MMM YYYY') }}</div>
							</div>
							<div class="column-edit-mobile" v-if="$resize && $mq.below(500) && ($auth.user().rights.procedure_crud || procedure.rights.edit || procedure.rights.clone)">
								<div class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef'+procedure.id" :id="'dropdownEdit'+procedure.id" data-toggle="dropdown">
										<icon-edit-mobile />
									</div>
									<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ procedure.id " :aria-labelledby="'dropdownEdit'+procedure.id">
										<div class="dropdown-item" @click="editProcedure(procedure.slug)" v-if="procedure.rights.edit"><div class="simple-text">{{ $t('general.edit') }}</div></div>
										<div class="dropdown-item" @click="showModal('clone', {slug: procedure.slug, type: 'procedure', from: 'procedures'})" v-if="procedure.rights.clone"><div class="simple-text">{{ $t('general.clone') }}</div></div>
										<div class="dropdown-item" @click="showModal('delete', {type: 'procedure', from: 'index_procedures', model: procedure})" v-if="procedure.rights.delete"><div class="simple-text">{{ $t('general.delete') }}</div></div>
									</div>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div v-if="$resize && $mq.above(501) && ($auth.user().rights.procedure_crud || procedure.rights.edit || procedure.rights.clone)" class="dropdown dropdown-edit">
								<div class="button-edit" :ref="'dropdownEditRef'+procedure.id" :id="'dropdownEdit'+procedure.id" data-toggle="dropdown">
									<icon-edit />
								</div>
								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ procedure.id " :aria-labelledby="'dropdownEdit'+procedure.id">
									<div class="dropdown-item" @click="editProcedure(procedure.slug)" v-if="procedure.rights.edit"><div class="simple-text">{{ $t('general.edit') }}</div></div>
									<div class="dropdown-item" @click="showModal('clone', {slug: procedure.slug, type: 'procedure', from: 'procedures'})" v-if="procedure.rights.clone"><div class="simple-text">{{ $t('general.clone') }}</div></div>
									<div class="dropdown-item" @click="showModal('delete', {type: 'procedure', from: 'index_procedures', model: procedure})" v-if="procedure.rights.delete"><div class="simple-text">{{ $t('general.delete') }}</div></div>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="row-space-tbf row-list-item">
						<div class="space-left"></div>
						<div class="content">
							<div class="column-empty-list">
								<div class="name"><img src="/build/images/no-results-found-inline.svg">{{ $t('procedures.empty-inline-user', {name: `${user.first_name} ${user.last_name ? user.last_name : ''}` }) }}</div>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
			</div>
		</div>
		<div class="sidebar-user">
			<div class="space-left-custom"></div>
			<div class="content">
				<div class="actions-header">
					<div class="simple-text">Detalii</div>

					<button v-if="user.rights.edit" @click="$router.push({ name: 'user-edit', params: {slug: user.slug} })">
						<div class="text">{{ $t('general.edit') }}</div>
					</button>
				</div>

				<div class="header-user">
					<div class="data">
						<div class="name">{{ `${user.first_name} ${user.last_name ? user.last_name : ''}` }}</div>
						<div class="tag" v-if="!user.dummy_account">{{ user.email }}</div>
					</div>

					<div class="image">
						<img :src="user.avatar" v-if="user.avatar">
						<div class="user-circle" v-else>
							<icon-user />
						</div>
					</div>
				</div>

				<div class="box-label-text" v-if="user.rights_name.length">
					<div class="label">{{ $t('users.rights') }}</div>
					<div class="text">{{ user.rights_name.map((el) => { return $t(`users.rights_name.${el}`)}).join(", ") }}</div>
				</div>

				<div class="box-label-text" v-if="user.roles.length">
					<div class="label">{{ $t('users.roles') }}</div>
					<div class="text">{{ user.roles.map((el) => { return el['name']}).join(", ") }}</div>
				</div>


				<div class="tracking-procedures" @click="showModal('procedure_check', { userSlug: user.slug })">
					<div class="icon"><icon-start-tracking /></div>
					<a>{{ $t('users.procedure-tracking', { n: user.procedure_checks} )}}</a>
				</div>

				<div class="box-table">
					<div class="header-title">
						<div class="icon"><icon-statistics /></div>
						<div class="title">{{ $t('procedures.statistics') }}</div>
					</div>
					<div class="box-radius">
						<div class="inline-data">
							<div class="label">{{ $t('procedures.total_time') }}</div>
							<div class="value">{{ user.analytics.total_time }}</div>
						</div>
						<div class="inline-data">
							<div class="label">{{ $t('procedures.number_visits') }}</div>
							<div class="value">{{ user.analytics.entries }}</div>
						</div>
						<div class="inline-data">
							<div class="label">{{ $t('procedures.average_time') }}</div>
							<div class="value">{{ user.analytics.average_time }}</div>
						</div>
					</div>
				</div>

				<div class="box-table" v-if="$auth.user().rights_name.includes('master_admin') || $auth.user().rights_name.includes('admin')">
					<div class="header-title">
						<div class="icon"><icon-bell /></div>
						<div class="title">{{ $t('users.notifications-create') }}</div>
					</div>
					<div class="box-radius">
						<template v-if="user.mail_notifications.length">
							<div class="inline-data" v-for="notification in user.mail_notifications">
								<div class="label">{{ $t(`settings-notifications.${notification}`) }}</div>
							</div>
						</template>
						<template v-else>
							<div class="inline-data">
								<div class="label">{{ $t(`settings-notifications.no_settings`) }}</div>
							</div>
						</template>
					</div>
				</div>
			</div>
			<div class="space-right-custom"></div>
		</div>
	</div>	
</template>

<script>
	import IconSearch from '../../Icons/Search'
	import IconArrow from '../../Icons/Arrow'
	import IconBell from '../../Icons/Bell'
	import IconUser from '../../Icons/User'
	import IconTrash from '../../Icons/Trash'
	import IconClose from '../../Icons/Close'
	import IconProcedures from '../../Icons/Procedures'
	import IconEdit from '../../Icons/EditDots'
	import IconSettings from '../../Icons/Settings'
	import IconEditMobile from '../../Icons/Edit'
	import IconStatistics from '../../Icons/Statistics'
	import LoaderItemsList from '../../PagesLoaders/ItemsList'
	import LoaderHeaderList from '../../PagesLoaders/HeaderList'
	import IconStartTracking from '../../Icons/StartTracking'

	export default {
		components: {
			IconSearch,
			IconArrow,
			IconUser,
			IconTrash,
			IconClose,
			IconProcedures,
			IconEdit,
			IconSettings,
			IconEditMobile,
			IconStatistics,
			LoaderItemsList,
			LoaderHeaderList,
			IconBell,
			IconStartTracking
		},
		data() {
			return {
				loaded: false,
				loadedList: false,
				search_filter: '',
				queryFiltersCount: 0,
				extendFilters: false,
				filterRoles: [],
				selectedRoles: [],
				searchRoles: '',
				filterCategories: [],
				selectedCategories: [],
				searchCategories: '',
				procedures: [],
				sortBy: '',
				sortDirection: 'asc',
				user: {}
			}
		},
		async mounted(){
			await this.getUserData()
			await this.getFilters()
			this.checkQueryFilters()

			setTimeout(() => {
				var title = this.$t('navbar.user');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshProceduresIndex', () => {
				this.getFilters()
				this.checkQueryFilters()
			})

			this.$root.$on('refreshProceduresTracking', () => {
				this.getUserData()
			})
		},
		watch: {
			search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
		},
		computed: {
			sortedProcedures: function(){
				return this.procedures.sort((p1,p2) => {
					let modifier = 1;
					if(this.sortDirection === 'desc') modifier = -1;
					if(p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier; if(p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
					return 0;
				});
			},
			filteredProcedures() {
				return getByKeyword(this.sortedProcedures, this.search_filter)
			},
			filteredRoles(){
				return getByKeywordFilter(this.filterRoles, this.searchRoles)
			}
		},
		methods: {
			async getUserData(){
				await axios.get(`/users/${this.$route.params.slug}`)
				.then(({data}) => {
					this.user = data.data
					this.procedures = data.data.procedures
				})
			},
			async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {roles: true, categories: true }})
				.then(({data}) => {
					this.filterRoles = data.data.roles
					this.filterCategories = data.data.categories
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.loaded = true })
			},
			showProcedure(itemSlug){
				this.$router.push({name: 'procedure-show', params: {slug: itemSlug}})
			},
			selectOptionFilter(filter, value) {
				if(typeof value == 'string'){
					if(this[filter].includes(value)){
						this[filter].splice(this[filter].indexOf(value), 1)
					}else{
						this[filter].push(value)
					}
				} else {
					if(this[filter].includes(parseInt(value))){
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1)
					}else{
						this[filter].push(parseInt(value))
					}
				}

				this.addFilterToRouteQuery(filter)
			},
			addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);

				if(filterName == 'selectedRoles' || filterName == 'all'){ dataQuery['roles'] = this.selectedRoles.toString(); }
				if(filterName == 'selectedCategories' || filterName == 'all'){ dataQuery['categories'] = this.selectedCategories.toString(); }

				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length

				this.$router.push({query : dataQuery });
			},
			clearFilter(filter){
				if(filter == 'all'){
					this.selectedRoles = []
					this.selectedCategories = []
				}else{
					this[filter] = []
				}

				this.addFilterToRouteQuery(filter)
			},
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ this.search_filter = dataQuery[key] }
					if(key == 'roles'){ this.selectedRoles = dataQuery[key].split(',').map(Number) }
					if(key == 'categories'){ this.selectedCategories = dataQuery[key].split(',').map(Number) }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length
			},
			sortList(column){
				if(column === this.sortBy) {
					this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
				}
				this.sortBy = column;
			},
			editProcedure(procedureSlug){
				this.$router.push({ name: 'procedure-edit', params: {slug: procedureSlug} })
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
		}
	}
	function getByKeyword(list, keyword) {
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => (item.name).toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
			return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss">
	@import "../../../styles/pages/show_user.scss";
</style>